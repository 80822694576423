var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AccountLayout, Token, TOKEN_PROGRAM_ID, u64 } from "@solana/spl-token";
import { Keypair, PublicKey, SystemProgram, Transaction, } from "@solana/web3.js";
import { MsgExecuteContract } from "@terra-money/terra.js";
import { isNativeDenom } from "..";
import { Bridge__factory, TokenImplementation__factory, } from "../ethers-contracts";
import { getBridgeFeeIx, ixFromRust } from "../solana";
import { importTokenWasm } from "../solana/wasm";
import { CHAIN_ID_SOLANA, createNonce, WSOL_ADDRESS } from "../utils";
export function getAllowanceEth(tokenBridgeAddress, tokenAddress, signer) {
    return __awaiter(this, void 0, void 0, function () {
        var token, signerAddress, allowance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = TokenImplementation__factory.connect(tokenAddress, signer);
                    return [4 /*yield*/, signer.getAddress()];
                case 1:
                    signerAddress = _a.sent();
                    return [4 /*yield*/, token.allowance(signerAddress, tokenBridgeAddress)];
                case 2:
                    allowance = _a.sent();
                    return [2 /*return*/, allowance];
            }
        });
    });
}
export function approveEth(tokenBridgeAddress, tokenAddress, signer, amount) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = TokenImplementation__factory.connect(tokenAddress, signer);
                    return [4 /*yield*/, token.approve(tokenBridgeAddress, amount)];
                case 1: return [4 /*yield*/, (_a.sent()).wait()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function transferFromEth(tokenBridgeAddress, signer, tokenAddress, amount, recipientChain, recipientAddress) {
    return __awaiter(this, void 0, void 0, function () {
        var fee, bridge, v, receipt;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fee = 0;
                    bridge = Bridge__factory.connect(tokenBridgeAddress, signer);
                    return [4 /*yield*/, bridge.transferTokens(tokenAddress, amount, recipientChain, recipientAddress, fee, createNonce())];
                case 1:
                    v = _a.sent();
                    return [4 /*yield*/, v.wait()];
                case 2:
                    receipt = _a.sent();
                    return [2 /*return*/, receipt];
            }
        });
    });
}
export function transferFromEthNative(tokenBridgeAddress, signer, amount, recipientChain, recipientAddress) {
    return __awaiter(this, void 0, void 0, function () {
        var fee, bridge, v, receipt;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fee = 0;
                    bridge = Bridge__factory.connect(tokenBridgeAddress, signer);
                    return [4 /*yield*/, bridge.wrapAndTransferETH(recipientChain, recipientAddress, fee, createNonce(), {
                            value: amount,
                        })];
                case 1:
                    v = _a.sent();
                    return [4 /*yield*/, v.wait()];
                case 2:
                    receipt = _a.sent();
                    return [2 /*return*/, receipt];
            }
        });
    });
}
export function transferFromTerra(walletAddress, tokenBridgeAddress, tokenAddress, amount, recipientChain, recipientAddress) {
    return __awaiter(this, void 0, void 0, function () {
        var nonce, isNativeAsset;
        var _a;
        return __generator(this, function (_b) {
            nonce = Math.round(Math.random() * 100000);
            isNativeAsset = isNativeDenom(tokenAddress);
            return [2 /*return*/, isNativeAsset
                    ? [
                        new MsgExecuteContract(walletAddress, tokenBridgeAddress, {
                            deposit_tokens: {},
                        }, (_a = {}, _a[tokenAddress] = amount, _a)),
                        new MsgExecuteContract(walletAddress, tokenBridgeAddress, {
                            initiate_transfer: {
                                asset: {
                                    amount: amount,
                                    info: {
                                        native_token: {
                                            denom: tokenAddress,
                                        },
                                    },
                                },
                                recipient_chain: recipientChain,
                                recipient: Buffer.from(recipientAddress).toString("base64"),
                                fee: "0",
                                nonce: nonce,
                            },
                        }, {}),
                    ]
                    : [
                        new MsgExecuteContract(walletAddress, tokenAddress, {
                            increase_allowance: {
                                spender: tokenBridgeAddress,
                                amount: amount,
                                expires: {
                                    never: {},
                                },
                            },
                        }, {}),
                        new MsgExecuteContract(walletAddress, tokenBridgeAddress, {
                            initiate_transfer: {
                                asset: {
                                    amount: amount,
                                    info: {
                                        token: {
                                            contract_addr: tokenAddress,
                                        },
                                    },
                                },
                                recipient_chain: recipientChain,
                                recipient: Buffer.from(recipientAddress).toString("base64"),
                                fee: "0",
                                nonce: nonce,
                            },
                        }, {}),
                    ]];
        });
    });
}
export function transferNativeSol(connection, bridgeAddress, tokenBridgeAddress, payerAddress, amount, targetAddress, targetChain) {
    return __awaiter(this, void 0, void 0, function () {
        var rentBalance, mintPublicKey, payerPublicKey, ancillaryKeypair, createAncillaryAccountIx, initialBalanceTransferIx, initAccountIx, _a, transfer_native_ix, approval_authority_address, nonce, fee, transferIx, approvalIx, messageKey, ix, closeAccountIx, blockhash, transaction;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Token.getMinBalanceRentForExemptAccount(connection)];
                case 1:
                    rentBalance = _b.sent();
                    mintPublicKey = new PublicKey(WSOL_ADDRESS);
                    payerPublicKey = new PublicKey(payerAddress);
                    ancillaryKeypair = Keypair.generate();
                    createAncillaryAccountIx = SystemProgram.createAccount({
                        fromPubkey: payerPublicKey,
                        newAccountPubkey: ancillaryKeypair.publicKey,
                        lamports: rentBalance,
                        space: AccountLayout.span,
                        programId: TOKEN_PROGRAM_ID,
                    });
                    initialBalanceTransferIx = SystemProgram.transfer({
                        fromPubkey: payerPublicKey,
                        lamports: Number(amount),
                        toPubkey: ancillaryKeypair.publicKey,
                    });
                    return [4 /*yield*/, Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, mintPublicKey, ancillaryKeypair.publicKey, payerPublicKey)];
                case 2:
                    initAccountIx = _b.sent();
                    return [4 /*yield*/, importTokenWasm()];
                case 3:
                    _a = _b.sent(), transfer_native_ix = _a.transfer_native_ix, approval_authority_address = _a.approval_authority_address;
                    nonce = createNonce().readUInt32LE(0);
                    fee = BigInt(0);
                    return [4 /*yield*/, getBridgeFeeIx(connection, bridgeAddress, payerAddress)];
                case 4:
                    transferIx = _b.sent();
                    approvalIx = Token.createApproveInstruction(TOKEN_PROGRAM_ID, ancillaryKeypair.publicKey, new PublicKey(approval_authority_address(tokenBridgeAddress)), payerPublicKey, //owner
                    [], new u64(amount.toString(16), 16));
                    messageKey = Keypair.generate();
                    ix = ixFromRust(transfer_native_ix(tokenBridgeAddress, bridgeAddress, payerAddress, messageKey.publicKey.toString(), ancillaryKeypair.publicKey.toString(), WSOL_ADDRESS, nonce, amount, fee, targetAddress, targetChain));
                    closeAccountIx = Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, ancillaryKeypair.publicKey, //account to close
                    payerPublicKey, //Remaining funds destination
                    payerPublicKey, //authority
                    []);
                    return [4 /*yield*/, connection.getRecentBlockhash()];
                case 5:
                    blockhash = (_b.sent()).blockhash;
                    transaction = new Transaction();
                    transaction.recentBlockhash = blockhash;
                    transaction.feePayer = new PublicKey(payerAddress);
                    transaction.add(createAncillaryAccountIx);
                    transaction.add(initialBalanceTransferIx);
                    transaction.add(initAccountIx);
                    transaction.add(transferIx, approvalIx, ix);
                    transaction.add(closeAccountIx);
                    transaction.partialSign(messageKey);
                    transaction.partialSign(ancillaryKeypair);
                    return [2 /*return*/, transaction];
            }
        });
    });
}
export function transferFromSolana(connection, bridgeAddress, tokenBridgeAddress, payerAddress, fromAddress, mintAddress, amount, targetAddress, targetChain, originAddress, originChain, fromOwnerAddress) {
    return __awaiter(this, void 0, void 0, function () {
        var nonce, fee, transferIx, _a, transfer_native_ix, transfer_wrapped_ix, approval_authority_address, approvalIx, messageKey, isSolanaNative, ix, transaction, blockhash;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    nonce = createNonce().readUInt32LE(0);
                    fee = BigInt(0);
                    return [4 /*yield*/, getBridgeFeeIx(connection, bridgeAddress, payerAddress)];
                case 1:
                    transferIx = _b.sent();
                    return [4 /*yield*/, importTokenWasm()];
                case 2:
                    _a = _b.sent(), transfer_native_ix = _a.transfer_native_ix, transfer_wrapped_ix = _a.transfer_wrapped_ix, approval_authority_address = _a.approval_authority_address;
                    approvalIx = Token.createApproveInstruction(TOKEN_PROGRAM_ID, new PublicKey(fromAddress), new PublicKey(approval_authority_address(tokenBridgeAddress)), new PublicKey(fromOwnerAddress || payerAddress), [], new u64(amount.toString(16), 16));
                    messageKey = Keypair.generate();
                    isSolanaNative = originChain === undefined || originChain === CHAIN_ID_SOLANA;
                    if (!isSolanaNative && !originAddress) {
                        throw new Error("originAddress is required when specifying originChain");
                    }
                    ix = ixFromRust(isSolanaNative
                        ? transfer_native_ix(tokenBridgeAddress, bridgeAddress, payerAddress, messageKey.publicKey.toString(), fromAddress, mintAddress, nonce, amount, fee, targetAddress, targetChain)
                        : transfer_wrapped_ix(tokenBridgeAddress, bridgeAddress, payerAddress, messageKey.publicKey.toString(), fromAddress, fromOwnerAddress || payerAddress, originChain, // checked by isSolanaNative
                        originAddress, // checked by throw
                        nonce, amount, fee, targetAddress, targetChain));
                    transaction = new Transaction().add(transferIx, approvalIx, ix);
                    return [4 /*yield*/, connection.getRecentBlockhash()];
                case 3:
                    blockhash = (_b.sent()).blockhash;
                    transaction.recentBlockhash = blockhash;
                    transaction.feePayer = new PublicKey(payerAddress);
                    transaction.partialSign(messageKey);
                    return [2 /*return*/, transaction];
            }
        });
    });
}
