/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
var _abi = [
    {
        stateMutability: "payable",
        type: "fallback",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
];
var Proxy__factory = /** @class */ (function () {
    function Proxy__factory() {
    }
    Proxy__factory.createInterface = function () {
        return new utils.Interface(_abi);
    };
    Proxy__factory.connect = function (address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    };
    Proxy__factory.abi = _abi;
    return Proxy__factory;
}());
export { Proxy__factory };
